<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-wrapper">
        <div class="row">

          <div class="col-lg-3 mb-lg-0 mb-5">
            <div class="footer-logo-box">
              <router-link to="/">
                <img v-if="$auth.getAppLogo() != ''" class="footer-logo" :src="$auth.getAppLogo()" alt="logo" />
                <div v-else class="logo-container">
                  <div class="logo-holder logo-4">
                    <router-link to="/">
                      <h3>{{ $auth.setting && $auth.setting.app_name }}</h3>
                    </router-link>
                  </div>
                </div>
              </router-link>
              <!-- <router-link to="/" class="mobi-logo"> <img class="footer-logo" :src="$auth.getAppLogo()"
                  alt="logo" /></router-link> -->
            </div>
            <p v-if="$auth.setting && $auth.setting.web_settings && $auth.setting.web_settings.footer_text && $auth.setting.web_settings.footer_text != '' && $auth.setting.web_settings.footer_text != null"
              class="">{{ $auth.setting.web_settings.footer_text.length > 250
                ? $auth.setting.web_settings.footer_text.slice(0, 210) + '...'
                : $auth.setting.web_settings.footer_text }}</p>
          </div>
          <!-- <div class="col-xl-5 col-lg-6 offset-xl-1"> -->
          <div class="col-lg-8 offset-xl-1">
            <div class="d-flex justify-content-lg-around gap-5 ">
              <div class="footer-left">
                <h4 class="">{{ $t('Quick Links') }} </h4>
                <ul class="footer-list">
                  <li class="footer-item">
                    <!-- <a target="_blank" :href="$auth.setting && $auth.setting.about_us_url
                      ? $auth.setting.about_us_url
                      : '#'" class="footer_link">{{ $t('About us') }}</a> -->
                    <a target="_blank" :href="this.about ? this.about : '#'" class="footer_link">{{ $t('About us')
                      }}</a>
                  </li>
                  <!-- <li class="footer-item">
                    <a target="_blank" :href="$auth.setting && $auth.setting.static_pages &&
                      $auth.setting.static_pages[1] &&
                      $auth.setting.static_pages[1].url
                      ? $auth.setting.static_pages[1].url
                      : '#'
                      " class="footer_link">{{ $t('Terms & Conditions') }}</a>
                  </li> -->
                  <li class="footer-item">
                    <a target="_blank" :href="this.terms ? this.terms : '#'" class="footer_link">{{ $t('Terms &
                      Conditions') }}</a>
                  </li>
                  <li class="footer-item" v-if="refundPolicy">
                    <a target="_blank" :href="this.refundPolicy ? this.refundPolicy : '#'" class="footer_link">{{
                      $t('Refund Policy') }}</a>
                  </li>

                  <li class="footer-item">
                    <a target="_blank" href="/support" class="footer_link">{{ $t('Support') }}</a>
                  </li>
                  <li class="footer-item" v-if="shippingDelivery">
                    <a target="_blank" :href="this.shippingDelivery ? this.shippingDelivery : '#'"
                      class="footer_link">{{
                        $t('Shipping & Delivery') }}</a>
                  </li>
                  <!-- <li class="footer-item">
                    <a target="_blank" :href="$auth.setting && $auth.setting.static_pages &&
                      $auth.setting.static_pages[0] &&
                      $auth.setting.static_pages[0].url
                      ? $auth.setting.static_pages[0].url
                      : '#'" class="footer_link">{{ $t('Privacy Policy') }}</a>
                  </li> -->
                  <li class="footer-item">
                    <a target="_blank" :href="this.privacy ? this.privacy
                      : '#'" class="footer_link">{{ $t('Privacy Policy') }}</a>
                  </li>
                  <li class="footer-item">
                    <a target="_blank" href="/faq" class="footer_link">{{ $t('FAQ') }}</a>
                  </li>
                </ul>
              </div>
              <div class="2">
                <h4 class="">{{
                  $t('Social Links') }}</h4>
                <ul class="footer-social">
                  <li class="footer-item">
                    <a target="_blank"
                      :href="$auth.setting && $auth.setting.web_settings && $auth.setting.web_settings.instagram ? $auth.setting.web_settings.instagram : '#'"
                      class="footer-link">
                      <img src="@/assets/images/insta-ic.svg" class="footer-social-ic" alt="">
                    </a>
                  </li>
                  <li class="footer-item">
                    <a target="_blank"
                      :href="$auth.setting && $auth.setting.web_settings && $auth.setting.web_settings.facebook_link ? $auth.setting.web_settings.facebook_link : '#'"
                      class="footer-link">
                      <img src="@/assets/images/facebook-ic.svg" class="footer-social-ic" alt="">
                    </a>
                  </li>
                  <li class="footer-item">
                    <a target="_blank"
                      :href="$auth.setting && $auth.setting.web_settings && $auth.setting.web_settings.twitter ? $auth.setting.web_settings.twitter : '#'"
                      class="footer-link">
                      <img src="@/assets/images/twitter-ic.svg" class="footer-social-ic" alt="">
                    </a>
                  </li>
                </ul>

                <button class="deonde_link" v-if="$auth.setting && $auth.setting.show_contact_developer == 'Yes'">
                  <img
                    src="https://storage.googleapis.com/deonde-prod/media/foodapp/original/food_flat_icon_17352169208623.png"
                    @error="$event.target.src = noImge" class="footer-sign-logo me-1" />
                  <a target="_blank"
                    :href="`https://www.deonde.co?utm_source=website&utm_medium=customer&utm_campaign=${$auth.getAppName()}`">{{
                      $t('Create your own store with Deonde') }}</a></button>

                <!-- <button class="btn deonde-button"><a
                    target="_blank"
                    :href="`https://www.deonde.co?utm_source=website&utm_medium=customer&utm_campaign=${$auth.getAppName()}`">Create
                    your own store with Deonde</a></button> -->
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="copy-right">

        <p v-if="$auth && $auth.setting && $auth.setting.show_contact_developer == 'Yes'">
          <!-- <span v-if="this.$auth.getVendorId() == '40818'">Powered by <a target="_blank" href="https://deonde.co/">{{
            $auth.getAppName() }}</a></span>
          <span v-else>Powered by <a target="_blank" href="#">{{ $auth.getAppName() }}</a></span> -->
          <span>Powered by <a target="_blank"
              :href="`https://www.deonde.co?utm_source=website&utm_medium=customer&utm_campaign=${$auth.getAppName()}`">Deonde</a></span>
        </p>
        <p v-else-if="this.$auth.getVendorId() == '42466'">
          <span><a>Copyright </a>&#169;
            <a target="_blank">{{ $t('Ethics Retails Chain Service Pvt Ltd.') }}</a> {{ year }}</span>
        </p>
        <p v-else>
          <span><a>Copyright </a>&#169;
            <a target="_blank">{{ $auth.getAppName() }}</a> {{ year }}</span>
        </p>
        <!-- <cookie-law theme="dark-lime">
          <div slot="message">This {{ $auth.getAppName() }} uses cookies to provide you with a great user experience.
            Please accept cookies for excellent optimal performance</div>
        </cookie-law> -->
      </div>
    </div>
  </footer>
</template>

<script>
import CookieLaw from "vue-cookie-law";
import { computed } from "vue";
export default {
  name: "footerpage",
  components: { CookieLaw },
  data() {
    return {
      terms: '',
      about: '',
      privacy: '',
      refundPolicy: '',
      shippingDelivery: '',
      dynamicUrl: ''
    }
  },
  computed: {
    year() {
      const currentDate = new Date();
      return currentDate.getFullYear();
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    setTimeout(() => {
      // let host = this.$auth && this.$auth.setting && this.$auth.setting.web_settings.website_url ? this.$auth.setting && this.$auth.setting.web_settings.website_url : window.location.hostname;
      this.$auth.setting && this.$auth.setting.static_pages.forEach((item) => {
        this.dynamicUrl = `/pages/${item.slug}`;
        // if (item.is_external_link == true) {
        //   window.open(item.url, '_blank');
        // }
        if (item.identifier == '1') {
          // this.terms = item.url
          this.terms = item.is_external_link == true ? item.url : this.dynamicUrl
        } else if (item.identifier == '2') {
          this.privacy = item.is_external_link == true ? item.url : this.dynamicUrl
        } else if (item.page_name == 'About' || item.page_name == 'About Us' || item.page_name == 'About us' || item.page_name == 'About US') {
          this.about = item.is_external_link == true ? item.url : this.dynamicUrl
        } else if (item.identifier == '4') {
          this.refundPolicy = item.is_external_link == true ? item.url : this.dynamicUrl
        } else if (item.identifier == '5') {
          this.shippingDelivery = item.is_external_link == true ? item.url : this.dynamicUrl
        }
      })
    }, 1000);
    this.updateIntercom();
  },
  watch: {
    $route() {
      this.updateIntercom();
    },
  },
  methods: {
    shouldShowIntercom() {
      const routeNamesToShowIntercom = ["restaurant"];
      return routeNamesToShowIntercom.includes(this.currentRouteName);
    },
    updateIntercom() {
      if (this.$auth.user && !this.shouldShowIntercom()) {
        // change condition for intercom
        // this.$auth.setting.is_intercom_configurations == 'Yes' && 
        if (this.$auth && this.$auth.setting && this.$auth.setting.intercom_details.is_enable == true) {
          this.initializeIntercom();
          //  document.getElementsByClassName('intercom-lightweight-app-launcher intercom-launcher')[0].style.display = "block";
          let intercomLauncher = document.getElementsByClassName('intercom-lightweight-app-launcher intercom-launcher')[0]
          if (intercomLauncher) {
            intercomLauncher.style.display = "block";
          }
        }
      } else {
        // document.getElementsByClassName('intercom-lightweight-app-launcher intercom-launcher')[0].style.display = "none"
        let intercomLauncher = document.getElementsByClassName('intercom-lightweight-app-launcher intercom-launcher')[0]
        if (intercomLauncher) {
          intercomLauncher.style.display = "none";
        }

        this.removeIntercom();
      }
    },
    removeIntercom() {

    },
    initializeIntercom() {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "d5zbw9m3",
        name: this.$auth.user.user_name,
        user_id: this.$auth.user.user_id,
        email: this.$auth.user.email,
        created_at: this.$auth.user.created_at,
      };

      window.v_id = this.$auth.setting.intercom_configuration_appid;

      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + w.v_id;
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    },
  },
};
</script>